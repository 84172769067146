import React, { FC } from "react";
import { SidebarDivider, SidebarList, SidebarPageItem } from "./index";
import { Path } from "paths";
import { SidebarItemGroup } from "./SidebarItemGroup";
import { useRecoilValue } from "recoil";
import { userState } from "base/Auth/userState";
import { ROLES } from "types/Auth";

export const SidebarContent: FC = () => {
  const { roles } = useRecoilValue(userState);

  return (
    <>
      <SidebarList>
        <SidebarPageItem path={Path.root} title="Home" testId="sidebar_Home" icon="home" />
        <SidebarItemGroup
          path={Path.inboundVisibilityList}
          placement={"first"}
          header={
            <SidebarPageItem
              path={Path.inboundVisibilityList}
              title="Inbounds Visibility tools"
              testId="sidebar_Inbound_visibility"
              icon="search"
            />
          }
        >
          <SidebarPageItem
            path={Path.forwardingInboundListView}
            navigationPath={Path.forwardingInboundListView}
            title="Inbound List View"
            testId="sidebar_ForwardingInboundListView"
          />
          <SidebarPageItem
            path={Path.forwardingInboundDetailView}
            navigationPath={Path.forwardingInboundDetailView}
            title="Inbound Detail View"
            testId="sidebar_ForwardingInboundDetailView"
          />
          <SidebarPageItem
            path={Path.boxReconciliationTool}
            navigationPath={Path.boxReconciliationTool}
            title="Box Reconciliation"
            testId="sidebar_BoxReconciliationTool"
          />
          <SidebarPageItem
            path={Path.inboundUnitReconciliationTool}
            navigationPath={Path.inboundUnitReconciliationTool}
            title="Inbound Unit Reconciliation"
            testId="sidebar_InboundUnitReconciliationTool"
          />
        </SidebarItemGroup>
        <SidebarItemGroup
          path={Path.inboundsList}
          placement={"first"}
          header={
            <SidebarPageItem
              path={Path.receiveAttribution}
              title="Inbounds tools"
              testId="sidebar_Inbounds"
              icon="inbounds"
            />
          }
        >
          <SidebarPageItem
            path={Path.receiveAttribution}
            navigationPath={Path.receiveAttribution}
            title="Receive Attribution"
            testId="sidebar_ReceiveAttribution"
          />
          <SidebarPageItem
            path={Path.boxAssignmentTool}
            navigationPath={Path.boxAssignmentTool}
            title="Box Assignment Tool"
            testId="sidebar_BoxAssignmentTool"
          />
          <SidebarPageItem
            path={Path.updateAsnPackageLink}
            navigationPath={Path.updateAsnPackageLink}
            title="ASN Package Link"
            testId="sidebar_UpdateAsnPackageLink"
          />
          <SidebarPageItem
            path={Path.asnAssignmentTool}
            navigationPath={Path.asnAssignmentTool}
            title="Non Receive Tool"
            testId="sidebar_AsnAssignmentTool"
          />
          <SidebarPageItem
            path={Path.eventAsnSplitTool}
            navigationPath={Path.eventAsnSplitTool}
            title="Event ASN Split Tool"
            testId="sidebar_EventAsnSplitTool"
          />
          <SidebarPageItem
            path={Path.manageReceivingZones}
            navigationPath={Path.manageReceivingZones}
            title="Manage Receiving Zones"
            testId="sidebar_ManageReceivingZones"
          />
          <SidebarPageItem
            path={Path.shipmentCompletionTool}
            navigationPath={Path.shipmentCompletionTool}
            title="Shipment Completion"
            testId="sidebar_ShipmentCompletionTool"
          />
        </SidebarItemGroup>
        <SidebarItemGroup
          path={Path.issueList}
          placement={"first"}
          header={
            <SidebarPageItem
              path={Path.issueActionTool}
              title="Non Compliance tools"
              testId="sidebar_NonCompliance"
              icon="document"
            />
          }
        >
          <SidebarPageItem
            path={Path.issueActionTool}
            navigationPath={Path.issueActionTool}
            title="Issue Action Tool"
            testId="sidebar_IssueActionTool"
          />
          <SidebarPageItem
            path={Path.unlabeledBoxTool}
            navigationPath={Path.unlabeledBoxTool}
            title="Unlabeled Box Tool"
            testId="sidebar_UnlabeledBoxTool"
          />
        </SidebarItemGroup>
        {roles.includes(ROLES.SORTATION_MANAGER) && (
          <SidebarItemGroup
            path={Path.sortationList}
            placement={"first"}
            header={
              <SidebarPageItem
                path={Path.sortCodeManager}
                title="Sortation tools"
                testId="sidebar_Sortation"
                icon="dolly"
              />
            }
          >
            <SidebarPageItem
              path={Path.sortCodeManager}
              navigationPath={Path.sortCodeManager}
              title="Sort Code Manager"
              testId="sidebar_SortCodeManager"
            />
            <SidebarPageItem
              path={Path.dockDoorLabels}
              navigationPath={Path.dockDoorLabels}
              title="Dock Door Labels"
              testId="sidebar_DockDoorLabels"
            />
          </SidebarItemGroup>
        )}
        <SidebarItemGroup
          path={Path.carrierNetworkList}
          placement={"first"}
          header={
            <SidebarPageItem
              path={Path.carrierNetworkLanes}
              title="Carrier Network tools"
              testId="sidebar_CarrierNetwork"
              icon="truck"
            />
          }
        >
          <SidebarPageItem
            path={Path.carrierNetworkLanes}
            navigationPath={Path.carrierNetworkLanes}
            title="Network Lane Manager"
            testId="sidebar_CarrierNetworkLanes"
          />
        </SidebarItemGroup>
        <SidebarItemGroup
          path={Path.parcelList}
          placement={"first"}
          header={
            <SidebarPageItem
              path={Path.parcelNetCHBProcess}
              title="Parcel tools"
              testId="sidebar_Parcel"
              icon="package"
            />
          }
        >
          <SidebarPageItem
            path={Path.parcelNetCHBProcess}
            navigationPath={Path.parcelNetCHBProcess}
            title="Process NetCHB Results"
            testId="sidebar_ProcessNetCHBResults"
          />
        </SidebarItemGroup>
        <SidebarItemGroup
          path={Path.CreditBlockList}
          placement={"first"}
          header={
            <SidebarPageItem path={Path.CreditBlockList} title="Finance" testId="sidebar_Finance" icon="invoice" />
          }
        >
          <SidebarPageItem
            path={Path.CreditBlockList}
            navigationPath={Path.CreditBlockList}
            title="Credit Blocks"
            testId="sidebar_CreditBlocks"
          />
        </SidebarItemGroup>
      </SidebarList>
      <SidebarDivider />
    </>
  );
};
