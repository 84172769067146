import { useState } from "react";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";
import { useNavigate } from "react-router-dom";

type FormValues = {
  sourceInboundShipment: string;
  targetInboundShipment: string;
  dsku: string;
  adjustmentQuantity: number;
};

export const useInboundUnitReconciliationTool = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { inboundVisibilityClient } = useClientsWithAuth();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      sourceInboundShipment: "",
      targetInboundShipment: "",
      dsku: "",
      adjustmentQuantity: 0,
    },
  });

  const sourceInboundShipmentController = useController({
    name: "sourceInboundShipment",
    control,
    rules: {
      required: "Source inbound shipment is required",
      pattern: {
        value: /^[1-9]\d*$/,
        message: "Source inbound shipment must be a positive number",
      },
    },
  });

  const targetInboundShipmentController = useController({
    name: "targetInboundShipment",
    control,
    rules: {
      required: "Target inbound shipment is required",
      pattern: {
        value: /^[1-9]\d*$/,
        message: "Target inbound shipment must be a positive number",
      },
    },
  });

  const dskuController = useController({
    name: "dsku",
    control,
    rules: {
      required: "DSKU is required",
      pattern: {
        value: /^[A-Za-z0-9]{11}$/,
        message: "DSKU must be exactly 11 alphanumeric characters",
      },
    },
  });

  const adjustmentQuantityController = useController({
    name: "adjustmentQuantity",
    control,
    rules: {
      required: "Adjustment quantity is required",
      min: {
        value: 1,
        message: "Adjustment quantity must be greater than 0",
      },
      max: {
        value: 100000,
        message: "Adjustment quantity cannot exceed 100000",
      },
    },
  });

  const onSubmitInboundUnitReconciliationTool: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsSubmitting(true);

      const sourceShipmentId = parseInt(data.sourceInboundShipment, 10);
      const targetShipmentId = parseInt(data.targetInboundShipment, 10);

      if (isNaN(sourceShipmentId) || isNaN(targetShipmentId)) {
        throw new Error("Invalid shipment IDs");
      }

      const payload = {
        sourceShipmentId,
        targetShipmentId,
        dsku: data.dsku,
        adjustmentQty: data.adjustmentQuantity,
      };

      await inboundVisibilityClient.submitInboundItemAdjustment(payload);
      toast.success("Inbound unit reconciliation submitted successfully", { position: "top-right" });
      resetAll();
    } catch (error) {
      handleInboundVisibilityError(
        { fn: "useInboundUnitReconciliationTool.onSubmitInboundUnitReconciliationTool" },
        error as Error
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  function resetAll() {
    reset();
    setIsSubmitting(false);
    navigate(".", { replace: true });
  }

  return {
    onSubmitInboundUnitReconciliationTool: handleSubmit(onSubmitInboundUnitReconciliationTool),
    sourceInboundShipmentController,
    targetInboundShipmentController,
    dskuController,
    adjustmentQuantityController,
    resetAll,
    isSubmitting,
  };
};
