import { Button, Card, ThemeProps, Input, Text, BoxReset } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useInboundUnitReconciliationTool } from "./useInboundUnitReconciliationTool";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const InboundUnitReconciliationTool = () => {
  const {
    sourceInboundShipmentController,
    targetInboundShipmentController,
    dskuController,
    adjustmentQuantityController,
    onSubmitInboundUnitReconciliationTool,
    resetAll,
    isSubmitting,
  } = useInboundUnitReconciliationTool();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitInboundUnitReconciliationTool}>
          <Text size="bodyLarge" bold>
            Inbound Unit Reconciliation Tool
          </Text>
          <Text size="body">Enter the source and target inbound shipments, DSKU, and adjustment quantity.</Text>

          <Input
            {...sourceInboundShipmentController.field}
            label="Source Inbound Shipment"
            helpText="Enter the source inbound shipment ID"
            required
            type="text"
            placeholder="123456"
            hasError={!!sourceInboundShipmentController.fieldState.error}
            errorMessage={sourceInboundShipmentController.fieldState.error?.message}
          />

          <Input
            {...targetInboundShipmentController.field}
            label="Target Inbound Shipment"
            helpText="Enter the target inbound shipment ID"
            required
            type="text"
            placeholder="789012"
            hasError={!!targetInboundShipmentController.fieldState.error}
            errorMessage={targetInboundShipmentController.fieldState.error?.message}
          />

          <Input
            {...dskuController.field}
            label="DSKU"
            helpText="Enter the DSKU (alphanumeric, max 11 characters)"
            required
            placeholder="D18S4BN5J3B"
            hasError={!!dskuController.fieldState.error}
            errorMessage={dskuController.fieldState.error?.message}
          />

          <Input
            {...adjustmentQuantityController.field}
            label="Adjustment Quantity"
            helpText="Enter the adjustment quantity (1-100000)"
            required
            type="number"
            placeholder="Enter the adjustment quantity (1-100000)"
            hasError={!!adjustmentQuantityController.fieldState.error}
            errorMessage={adjustmentQuantityController.fieldState.error?.message}
          />

          <BoxReset marginTop="S4" marginBottom="S4">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button style={{ marginRight: "16px" }} secondary onClick={resetAll} loading={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Submit
              </Button>
            </div>
          </BoxReset>
        </StyledForm>
      </Card>
    </Container>
  );
};
