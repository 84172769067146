import { Modal, Select, SelectOptionType, Stack, StyledButton, ThemeProps, Title, IconV2 } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";

const StyledButtonStack = styled(Stack)`
  width: 100%;
  justify-content: flex-end;
`;
interface ChangeStatusModalProps {
  setStatus: (status: string) => void;
  setStatusReason: (statusReason: string) => void;
  statusOptions: SelectOptionType[];
  statusReasonOptions: SelectOptionType[];
  updateSellerStatus: () => void;
  isStatusModalOpen: boolean;
  closeStatusModal: () => void;
  isUpdateStatusDisabled: boolean;
  status: string;
  statusReason: string;
  sellerId: string;
}

export const ChangeStatusModal: FC<ChangeStatusModalProps> = ({
  updateSellerStatus,
  statusOptions,
  statusReasonOptions,
  setStatus,
  setStatusReason,
  isStatusModalOpen,
  closeStatusModal,
  isUpdateStatusDisabled,
  status,
  statusReason,
  sellerId,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleProceed = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    updateSellerStatus();
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    closeStatusModal();
  };

  return (
    <Modal show={isStatusModalOpen} onClose={closeStatusModal}>
      <Stack gap="S4">
        <Title as="h3" displayAs="h4">
          Change Status
        </Title>
        {!showConfirmation ? (
          <>
            <Select
              onChange={({ value }) => setStatus(value)}
              options={statusOptions}
              label="Change the status"
              placeholder="Change Status"
              required
              value={statusOptions.find((option) => option.value === status)}
            />
            <Select
              onChange={({ value }) => setStatusReason(value)}
              options={statusReasonOptions}
              label="Select reason code for status change"
              placeholder="Select Reason Code"
              required
              value={statusReasonOptions.find((option) => option.value === statusReason) || null}
            />
            <StyledButtonStack direction="HORIZONTAL">
              <StyledButton secondary onClick={closeStatusModal}>
                Cancel
              </StyledButton>
              <StyledButton onClick={handleProceed} disabled={isUpdateStatusDisabled}>
                Proceed
              </StyledButton>
            </StyledButtonStack>
          </>
        ) : (
          <ConfirmationModal
            status={status}
            sellerId={sellerId}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            isUpdateStatusDisabled={isUpdateStatusDisabled}
          />
        )}
      </Stack>
    </Modal>
  );
};
