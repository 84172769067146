import { Route } from "react-router-dom";
import { Path } from "paths";
import { NavBar } from "components/NavBar";
import { Container } from "components/Container";
import { BoxReconciliationTool } from "./BoxReconciliationTool/BoxReconciliationTool";
import { InboundListView } from "./InboundListView.tsx/InboundListView";
import { InboundDetailView } from "./InboundDetailView/InboundDetailView";
import { InboundUnitReconciliationTool } from "./InboundUnitReconciliationTool/InboundUnitReconciliationTool";

export const inboundVisibilityHeaderRoutes = [
  <Route
    key={"inboundVisibilityListHeader"}
    path={Path.inboundVisibilityList}
    element={<NavBar breadcrumbs={[{ pageName: "Inbound Visibility" }]} />}
  />,
  <Route
    key={"boxReconciliationToolHeader"}
    path={Path.boxReconciliationTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbound Visibility" }, { pageName: "Box Reconciliation Tool" }]} />}
  />,
  <Route
    key={"inboundUnitReconciliationToolHeader"}
    path={Path.inboundUnitReconciliationTool}
    element={
      <NavBar breadcrumbs={[{ pageName: "Inbound Visibility" }, { pageName: "Inbound Unit Reconciliation Tool" }]} />
    }
  />,
  <Route
    key={"forwardingInboundListViewHeader"}
    path={Path.forwardingInboundListView}
    element={
      <NavBar breadcrumbs={[{ pageName: "Inbound Visibility" }, { pageName: "Forwarding Inbound List View" }]} />
    }
  />,
  <Route
    key={"forwardingInboundDetailViewHeader"}
    path={Path.forwardingInboundDetailView}
    element={
      <NavBar breadcrumbs={[{ pageName: "Inbound Visibility" }, { pageName: "Forwarding Inbound Detail View" }]} />
    }
  />,
];

export const inboundVisibilityRoutes = [
  <Route
    key={"inboundVisibilityList"}
    path={Path.inboundVisibilityList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key={"boxReconciliationTool"} path={Path.boxReconciliationTool} element={<BoxReconciliationTool />} />,
  <Route
    key={"inboundUnitReconciliationTool"}
    path={Path.inboundUnitReconciliationTool}
    element={<InboundUnitReconciliationTool />}
  />,
  <Route key={"forwardingInboundListView"} path={Path.forwardingInboundListView} element={<InboundListView />} />,
  <Route key={"forwardingInboundDetailView"} path={Path.forwardingInboundDetailView} element={<InboundDetailView />} />,
];
