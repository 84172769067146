import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { useAsyncFn, useMount } from "react-use";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";

export interface InboundListViewEntry {
  id: number;
  createdAt: Date;
  asnLabel: string;
  shippingPlanId: number;
  shipmentId: number;
  sellerId: string;
  facilityId: string;
  cartonsExpected: number;
  cartonsPendingScan: number;
  cartonsScanned: number;
  cartonsReconciled: number;
  cartonsReceivedAtFc: number;
  cartonsUnknown: number;
  hasSlaViolation: boolean;
}

export const useInboundListView = () => {
  const { inboundVisibilityClient } = useClientsWithAuth();
  const [inboundListEntries, setInboundListEntries] = useState<InboundListViewEntry[]>([]);

  useMount(() => {
    loadInboundsList();
  });

  const [{ loading: loadingEntries }, loadInboundsList] = useAsyncFn(async () => {
    try {
      const [ecomForwardingInbounds, getStorageToEcomForwardingInbounds] = await Promise.all([
        inboundVisibilityClient.getEcomForwardingInbounds(),
        inboundVisibilityClient.getStorageToEcomForwardingInbounds(),
      ]);
      const concatenatedEntries = [...ecomForwardingInbounds, ...getStorageToEcomForwardingInbounds];
      setInboundListEntries(concatenatedEntries);
    } catch (error) {
      handleInboundVisibilityError({ fn: "useInboundListView" }, error);
    }
  }, [inboundVisibilityClient, setInboundListEntries]);
  return { loadingEntries, inboundListEntries };
};
