import { ApiClient, AxiosInstance } from "@deliverr/base-client";
import { DeliverrError } from "@deliverr/errors";
import { LabelaryErrors } from "./LabelaryErrors";

export class LabelaryClient extends ApiClient {
  private client: AxiosInstance;
  constructor() {
    // Required to call the parent constructor
    super({ baseURL: process.env.REACT_APP_LABELARY_URL! });

    // Axyos is an unmodified instance of Axios
    this.client = this.axyos.create({
      baseURL: process.env.REACT_APP_LABELARY_URL!,
    });
  }

  public async convertZPLToPDF(zpl: string): Promise<string> {
    try {
      const resp = await this.client.post(`/v1/printers/8dpmm/labels/4x6/`, zpl, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      });
      return resp.data;
    } catch (err) {
      throw new DeliverrError(LabelaryErrors.FORMAT_CONVERSION_FAILED);
    }
  }
}
