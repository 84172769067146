import { Stack, StyledButton, ThemeProps, IconV2 } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FC } from "react";
import { OrganizationStatus } from "@deliverr/business-types";

const ConfirmationBox = styled.div<ThemeProps>`
  ${({ theme }) => `
    background-color: ${theme.colors.NEUTRAL[20]};
    border: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
    padding: ${theme.spacing.S5};
  `}
`;

const DangerBox = styled.div<ThemeProps>`
  ${({ theme }) => `
    background-color: ${theme.colors.RED[100]};
    border: ${theme.border.width.B1} solid ${theme.colors.RED[300]};
    color: ${theme.colors.NEUTRAL[500]};
    padding: ${theme.spacing.S3};
    margin-top: ${theme.spacing.S2};
  `}
`;

const WarningBox = styled.div<ThemeProps>`
  ${({ theme }) => `
    background-color: ${theme.colors.YELLOW[100]};
    border: ${theme.border.width.B1} solid ${theme.colors.YELLOW[300]};
    color: ${theme.colors.NEUTRAL[500]};
    padding: ${theme.spacing.S3};
    margin-top: ${theme.spacing.S2};
  `}
`;

const RedText = styled.span<ThemeProps>`
  ${({ theme }) => `
    color: ${theme.colors.RED[400]};
  `}
`;

const StyledDangerIcon = styled(IconV2)<ThemeProps>`
  ${({ theme }) => `
    color: ${theme.colors.RED[400]};
    margin-right: ${theme.spacing.S2};
  `}
`;

const StyledWarningIcon = styled(IconV2)<ThemeProps>`
  ${({ theme }) => `
    color: ${theme.colors.YELLOW[400]};
    margin-right: ${theme.spacing.S2};
  `}
`;

const BulletList = styled.ul<ThemeProps>`
  ${({ theme }) => `
    list-style-type: disc;
    padding-left: ${theme.spacing.S5};
    margin-top: ${theme.spacing.S2};
  `}
`;

const StyledButtonStack = styled(Stack)`
  width: 100%;
  justify-content: flex-end;
`;

interface ConfirmationModalProps {
  status: string;
  sellerId: string;
  onCancel: () => void;
  onConfirm: () => void;
  isUpdateStatusDisabled: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  status,
  sellerId,
  onCancel,
  onConfirm,
  isUpdateStatusDisabled,
}) => {
  const renderWarning = () => {
    if (status === OrganizationStatus.INACTIVE) {
      return (
        <>
          <div>
            <strong>
              Are you sure you want to offboard the merchant <RedText>{sellerId}</RedText> ?
            </strong>
          </div>
          <DangerBox>
            <div>
              <strong>
                <StyledDangerIcon type="exclamation-triangle" size="md" />
                Danger: Marking a merchant INACTIVE will result in:
              </strong>
            </div>
            <BulletList>
              <li>Merchant won't be able to login on seller portal</li>
              <li>Deactivating all active channel integrations</li>
              <li>Deletion of seller chargebee payment methods</li>
              <li>
                Merchant will be marked churned and its billing and customer email will be marked to churn@deliverr.com
              </li>
            </BulletList>
          </DangerBox>
        </>
      );
    }

    if (status === OrganizationStatus.SUSPENDED) {
      return (
        <>
          <div>
            <strong>
              Are you sure you want to suspend the merchant <RedText>{sellerId}</RedText> ?
            </strong>
          </div>
          <WarningBox>
            <div>
              <strong>
                <StyledWarningIcon type="exclamation-triangle" size="md" />
                Warning: Marking a merchant SUSPENDED will result in:
              </strong>
            </div>
            <BulletList>
              <li>Merchant channel integrations will be disconnected</li>
              <li>Merchant will be able to login with minimum access - Billing and Reporting only</li>
            </BulletList>
          </WarningBox>
        </>
      );
    }

    if (status === OrganizationStatus.ACTIVE) {
      return (
        <div>
          <strong>
            <StyledDangerIcon type="exclamation-triangle" size="md" />
            Are you sure you want to change the status of merchant <RedText>{sellerId}</RedText> to ACTIVE ?
          </strong>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ConfirmationBox>{renderWarning()}</ConfirmationBox>
      <StyledButtonStack direction="HORIZONTAL">
        <StyledButton secondary onClick={onCancel}>
          No, Cancel
        </StyledButton>
        <StyledButton onClick={onConfirm} disabled={isUpdateStatusDisabled}>
          Yes, Confirm
        </StyledButton>
      </StyledButtonStack>
    </>
  );
};
